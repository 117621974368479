<template>
  <div>
    <div :class="['trolley-btn', Object.keys($store.state.selectedWorkList).length === 0 ? '' : 'trolley-btn-2']"
         @click="spreadChange">
      <div class="trolley-icon-box">
        <img class="trolley-icon" :src="require('@/assets/trolley-icon.png')" alt="">
      </div>
      <div class="trolley-text">作业清单</div>
      <div class="num" v-show="Object.keys($store.state.selectedWorkList).length > 0">
        {{Object.keys($store.state.selectedWorkList).length}}
      </div>
    </div>
    <div class="bg" v-show="spread" @click="spreadChange"></div>
    <div class="modal" v-show="spread">
      <section class="inventory" ref="inventory" :style="{height : inventoryHeight}">
        <header class="header">
          <div>作业清单</div>
          <div class="all-del" @click="allDel">全部清空</div>
        </header>
        <div class="inventory-content">
          <div class="inventory-list" v-for="item in $store.state.selectedWorkList" :key="item.id">
            <div class="inventory-list-content">
              <div class="name">
                <div>{{ item.name }}</div>
                <div class="module-name" v-html="item.wordList ? `${item.wordList.length}个已添加` : item.moduleName"></div>
              </div>
              <div :class="['type', item.typeList ? '' : 'hidden']">
                <div class="label">模式：</div>
                <ul class="tabs" v-if="item.typeList">
                  <template v-for="type in item.typeList">
                    <!--<li :key="type.id" v-if="type.active||type.selected">{{type.name}}</li>-->
                    <li :key="type.id">{{type.name}}</li>
                  </template>
                </ul>
              </div>
              <div class="time">
                <img src="../../../../assets/time.png" alt="time">
                <div>预计{{item.time}}分钟</div>
              </div>
              <div class="btn-group">
                <div class="btn" @click="del(item.id)">移除</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="footer">
        <div class="book">
          <div class="icon"></div>
          <div class="text">
            <div>
              <span>已添加</span><span
                class="num">{{Object.keys($store.state.selectedWorkList).length}}</span><span>题</span>
            </div>
            <div class="time">预计{{time}}分钟完成</div>
          </div>
          <div class="switch-btn" @click="spreadChange">
            <div>
              <div :class="['icon', spread ? 'rotate-180' : '']"></div>
              <div>{{spread ? '收起' : '展开'}}</div>
            </div>
          </div>
        </div>
        <div class="right" :style="{width : spread ? '100%' : '0'}">
          <div class="submit-btn" :style="{opacity : spread ? '1' : '0'}" @click="workSubmit">全部选好了，去布置</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'trolley',
    data() {
      return {
        spread: false,
        inventoryHeight: 'auto'
      }
    },
    computed: {
      time: function () {
        const {selectedWorkList} = this.$store.state
        let time = 0
        Object.keys(selectedWorkList).forEach((item) => {
          time += selectedWorkList[item].time
        })
        return time
      }
    },
    methods: {
      spreadChange() {
        if (Object.keys(this.$store.state.selectedWorkList).length === 0) return
        this.spread = !this.spread
      },
      del(id) {
        const obj = {
          id
        }
        this.$store.commit('listDel', obj)
        if (JSON.stringify(this.$store.state.selectedWorkList) === '{}') this.spread = false
        this.listState()
      },
      allDel() {
        this.$store.commit('listClear')
        this.spread = false
        this.listState()
      },
      listState() {
        this.$emit('listState')
      },
      workSubmit() {
        this.$router.push({name: 'workSubmit'})
      }
    }
  }
</script>

<style scoped lang="scss">
  .trolley-btn {
    //background-image: url("../../../../assets/work-1.png");
    //background-size: 100% 100%;
    width: 56px;
    height: 92px;
    background: #309AF2;
    border-radius: 10px;
    position: fixed;
    right: 14px;
    bottom: 130px;
    z-index: 1000;
    padding: 8px 8px 12px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: #1485E3;
      .trolley-icon-box {
        background: linear-gradient(360deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.08) 100%);
      }
    }

    .trolley-icon-box {
      width: 40px;
      height: 28px;
      border-radius: 6px;
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      .trolley-icon {
        width: 20px;
        height: 20px;
      }
    }

    .trolley-text {
      width: 28px;
      height: 40px;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 20px;
    }

    .num {
      background-color: #FF3C30;
      width: 20px;
      height: 20px;
      line-height: 18px;
      border-radius: 50%;
      text-align: center;
      color: #fff;
      font-size: 10px;
      position: absolute;
      top: -6px;
      right: -6px;
      border: 2px solid #FFFFFF;
    }
  }

  @media screen and (min-width: 1268px) {
    .trolley-btn {
      right: calc(50% - 550px - 70px) !important;
      bottom: 130px !important;
    }
  }
  //.trolley-btn-2 {
  //  background-image: url("../../../../assets/work-2.png");
  //}

  .bg {
    background-color: rgba(0, 0, 0, .3);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 998;
  }

  .modal {
    max-width: 1100px;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }

  .inventory {
    transition: height .3s;

    .header {
      @include flex(space-between);
      background-color: #F6F6F6;
      height: 54px;
      padding: 0 56px;
      border-radius: 16px 16px 0 0;
      font-size: 17px;

      .all-del {
        color: #FF3C30;
        cursor: pointer;
      }
    }

    .inventory-content {
      background-color: #fff;
      max-height: calc(100vh - 166px);
      padding: 10px 28px;
      overflow: auto;

      .inventory-list {
        padding: 0 28px;
        border-radius: 8px;

        &:hover {
          background-color: #F6F6F6;
        }

        .name {
          flex: 1;
          font-size: 17px;
          color: #333;
          overflow: hidden;
        }

        .module-name {
          margin-top: 2px;
          font-size: 14px;
          color: #999;
          white-space: nowrap;
          transition: transform 1s;
        }

        .type {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          @include font(12px, #999999);
          margin-right: 20px;
        }

        .label {
          white-space: nowrap;
        }

        .tabs {
          li {
            display: inline-block;
            background-color: #EAF4FD;
            height: 26px;
            line-height: 26px;
            padding: 0 10px;
            border-radius: 4px;
            margin-right: 4px;
            margin-bottom: 4px;
            @include font(14px, #309AF2, center);
            cursor: pointer;
          }
        }

        .time {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          width: 100px;
          font-size: 14px;
          color: #309AF2;

          img {
            margin-right: 4px;
            width: 12px;
            height: 12px;
          }
        }

        .btn-group {
          flex-shrink: 0;
          display: flex;
          justify-content: flex-end;
          width: 86px;
        }

        .btn {
          background-color: #fff;
          width: 67px;
          height: 30px;
          line-height: 30px;
          border-radius: 15px;
          @include font(13px, #FF3C30, center);
          cursor: pointer;

          &:hover {
            background-color: #E5E5E5;
          }
        }
      }

      .inventory-list-content {
        @include flex;
        min-height: 70px;
        border-bottom: 1px solid #F1F1F1;
        overflow: hidden;
      }
    }
  }

  .footer {
    @include flex(flex-end);
    background-color: #fff;
    height: 84px;
    @include font;
    padding: 0 28px;
    box-shadow: 0 -6px 15px 0 rgba(0, 0, 0, .06);

    .book {
      @include flex;

      .icon {
        @include icon(url("../../../../assets/work-3.png"), 40px, 50px);
        margin-right: 10px;
      }

      .text {
        white-space: nowrap;
      }

      .time {
        @include font(15px, #999);
      }

      .switch-btn {
        @include flex;
        background-color: #F6F6F6;
        width: 44px;
        height: 44px;
        @include font(12px, #666, center);
        margin-left: 20px;
        margin-right: 28px;
        border-radius: 1px;
        cursor: pointer;

        .icon {
          @include icon(url("../../../../assets/spread.png"), 7px, 4px);
          margin: 0 auto 6px;
          transition: transform .3s;
        }
      }
    }

    .right {
      @include flex(flex-end);
      overflow: hidden;
      transition: width .5s;
    }

    .submit-btn {
      background: linear-gradient(to left, #6DBBFC, #309AF2);
      width: 250px;
      height: 40px;
      line-height: 40px;
      border-radius: 4px;
      @include font(17px, #fff, center);
      transition: opacity .3s;
      cursor: pointer;

      &:hover {
        background: #309AF2;
      }
    }
  }
</style>
